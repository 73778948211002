import { tns } from "tiny-slider/src/tiny-slider";

const testimonialCarousel = () => {
  const carousel = document.querySelector(".testimonial-carousel__carousel");

  const testimonialCarousel = tns({
    container: carousel,
    nav: false,
    controls: false,
  });

  const prevTestimonial = document.getElementById("prevTestimonial");
  if (prevTestimonial)
    prevTestimonial.addEventListener("click", (e) => {
      e.preventDefault();
      testimonialCarousel.goTo("prev");
    });

  const nextTestimonial = document.getElementById("nextTestimonial");
  if (nextTestimonial)
    nextTestimonial.addEventListener("click", (e) => {
      e.preventDefault();
      testimonialCarousel.goTo("next");
    });
};

export default testimonialCarousel;
