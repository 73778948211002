import animateIn from "../components/animateIn";
import contactMap from "../components/contactMap";
import mobileMenu from "../components/mobileNav";
import testimonialCarousel from "../components/testimonialCarousel";

export default {
  init() {
    contactMap();
    animateIn();
    mobileMenu();
    testimonialCarousel();
  },
  finalize() {},
};
