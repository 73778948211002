const mobileMenu = () => {
  const hamburgerMenu = document.getElementById("header__nav-icon");
  const html = document.getElementsByTagName("html")[0];
  if (!hamburgerMenu) return;
  const primaryNav = document.querySelector(".header__nav-primary");
  const primaryNavLi = primaryNav.getElementsByTagName("li");

  hamburgerMenu.addEventListener("click", () => {
    if (hamburgerMenu.classList.contains("mobile-open")) {
      html.style.overflow = "scroll";
      document.body.classList.remove("mobile-open");
      hamburgerMenu.classList.remove("mobile-open");
      if (primaryNavLi.length > 0) {
        toggleAnimationClasses(primaryNavLi);
      }
      return;
    }
    html.style.overflow = "hidden";
    document.body.classList.add("mobile-open");
    hamburgerMenu.classList.add("mobile-open");
    if (primaryNavLi.length > 0) {
      toggleAnimationClasses(primaryNavLi);
    }
  });

  window.addEventListener("load", () => {
    if (primaryNavLi.length > 0 && window.innerWidth < 992) {
      for (let index = 0; index < primaryNavLi.length; index++) {
        primaryNavLi[index].classList.add(
          "animate",
          "mobile-fade-in-up",
          `animate-delay-${index + 1}`
        );
      }
    }
  });

  const toggleAnimationClasses = (primaryNavLi) => {
    for (let index = 0; index < primaryNavLi.length; index++) {
      primaryNavLi[index].classList.toggle("animated");
      primaryNavLi[index].classList.toggle("seen");
    }
  };
};

export default mobileMenu;
