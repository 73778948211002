export const elementInView = (el, scrollOffset = 0) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) - scrollOffset
  );
};

export const throttle = (callback, time) => {
  let throttleTimer;

  if (throttleTimer) return;

  throttleTimer = true;

  setTimeout(() => {
    callback;
    throttleTimer = false;
  }, time);
};
