import { elementInView, throttle } from "../util/utils";

const animateIn = () => {
  const scrollElements = document.querySelectorAll(".animate");
  const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

  const handleScrollAnimation = () => {
    scrollElements.forEach((el) => {
      let offset = 100;
      if (el.classList.contains("animate-no-margin")) {
        offset = 0;
      }
      if (elementInView(el, offset)) {
        displayScrollElement(el);
      }
    });
  };

  window.addEventListener("scroll", () => {
    if (mediaQuery && !mediaQuery.matches) {
      throttle(handleScrollAnimation(), 250);
    }
  });

  window.onload = () => {
    if (mediaQuery && !mediaQuery.matches) {
      handleScrollAnimation();
    }
  };

  window.addEventListener("load", () => {
    if (mediaQuery && !mediaQuery.matches) {
      handleScrollAnimation();
    }
  });
};

const displayScrollElement = (element) => {
  element.classList.add("animated", "seen");
};

export default animateIn;
